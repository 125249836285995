import AUTH from "@/redux/Auth";
import { useAppDispatch } from "@/redux/store";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useRouter } from "next/navigation";
import { deleteCookie } from "cookies-next";
import defaultImage from "@/public/images/default_image.jpg";
import MyLink from "../myLink/MyLink";

type Props = {
  dashboard: boolean;
  loggedUser: any;
  setShowProfileMenu: any;
  showProfileMenu: any;
};

const UserLoggedMenu: React.FC<Props> = ({
  dashboard = false,
  loggedUser,
  setShowProfileMenu,
  showProfileMenu,
}) => {
  const router = useRouter();

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    deleteCookie("Education_token");
    deleteCookie("teacher__teacher");
    deleteCookie("Education_User_token");
    deleteCookie("seveezToken");
    localStorage.removeItem("isLogged");
    localStorage.removeItem("username");
    localStorage.removeItem("image");
    dispatch(AUTH.slice.actions.logout());
    router.push("/login");
  };
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div
        className={`absolute top-[6rem] rounded-lg  w-[270px] flex flex-col gap-3 bg-[#2F3943] p-3 z-50 ${
          dashboard ? "left-5" : "left-5 md:left-16"
        }`}
      >
        <div
          className="flex items-center gap-2"
          onClick={() => {
            setShowProfileMenu(!showProfileMenu);
          }}
        >
          <Image
            src={loggedUser.userData.image || defaultImage}
            alt={loggedUser.userData.name}
            width={47}
            height={47}
            className="rounded-md w-[30px] md:w-[47px]  h-[30px] md:h-[47px]  object-cover"
          />
          <div className="flex flex-col gap-1">
            <p className="font-[500] text-white text-right text-xs md:text-lg">
              {loggedUser.userData.name.split(" ").slice(0, 2).join(" ")}
            </p>
            <p className="bg-primaryColor rounded-full px-2 w-fit text-sm">
              {loggedUser.userData.money
                ? `${loggedUser.userData.money} جنية`
                : "0 جنية"}
            </p>
          </div>
        </div>
        <MyLink
          href="/dashboard/"
          className=" flex items-center gap-3  p-2 text-white rounded-lg duration-200 transition-all  group text-[18px]"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.22209 4.60105C6.66665 4.304 7.13344 4.04636 7.6171 3.82976C8.98898 3.21539 9.67491 2.9082 10.5875 3.4994C11.5 4.09061 11.5 5.06041 11.5 7.00001V8.50001C11.5 10.3856 11.5 11.3284 12.0858 11.9142C12.6716 12.5 13.6144 12.5 15.5 12.5H17C18.9396 12.5 19.9094 12.5 20.5006 13.4125C21.0918 14.3251 20.7846 15.011 20.1702 16.3829C19.9536 16.8666 19.696 17.3334 19.399 17.7779C18.3551 19.3402 16.8714 20.5578 15.1355 21.2769C13.3996 21.9959 11.4895 22.184 9.64665 21.8175C7.80383 21.4509 6.11109 20.5461 4.78249 19.2175C3.45389 17.8889 2.5491 16.1962 2.18254 14.3534C1.81598 12.5105 2.00412 10.6004 2.72315 8.86451C3.44218 7.12861 4.65982 5.64492 6.22209 4.60105Z"
              fill="white"
            />
            <path
              d="M21.446 7.06901C20.6342 5.00831 18.9917 3.36579 16.931 2.55398C15.3895 1.94669 14 3.34316 14 5.00002V9.00002C14 9.5523 14.4477 10 15 10H19C20.6569 10 22.0533 8.61055 21.446 7.06901Z"
              fill="white"
            />
          </svg>
          <span className="">لوحة التحكم</span>
        </MyLink>
        <MyLink
          href="/"
          className=" flex items-center gap-3  p-2 text-white rounded-lg duration-200 transition-all  group text-[18px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M2.33537 7.87495C1.79491 9.00229 1.98463 10.3208 2.36407 12.9579L2.64284 14.8952C3.13025 18.2827 3.37396 19.9764 4.54903 20.9882C5.72409 22 7.44737 22 10.8939 22H13.1061C16.5526 22 18.2759 22 19.451 20.9882C20.626 19.9764 20.8697 18.2827 21.3572 14.8952L21.6359 12.9579C22.0154 10.3208 22.2051 9.00229 21.6646 7.87495C21.1242 6.7476 19.9738 6.06234 17.6731 4.69181L16.2882 3.86687C14.199 2.62229 13.1543 2 12 2C10.8457 2 9.80104 2.62229 7.71175 3.86687L6.32691 4.69181C4.02619 6.06234 2.87583 6.7476 2.33537 7.87495ZM8.2501 17.9998C8.2501 17.5856 8.58589 17.2498 9.0001 17.2498H15.0001C15.4143 17.2498 15.7501 17.5856 15.7501 17.9998C15.7501 18.414 15.4143 18.7498 15.0001 18.7498H9.0001C8.58589 18.7498 8.2501 18.414 8.2501 17.9998Z"
              fill="white"
            />
          </svg>
          <span className="">الصفحه الرئيسية</span>
        </MyLink>
        <MyLink
          href="/myCourses"
          className=" flex items-center gap-3  p-2 text-white rounded-lg duration-200 transition-all  group text-[18px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.50989 2.00001H15.49C15.7225 1.99995 15.9007 1.99991 16.0565 2.01515C17.1643 2.12352 18.0711 2.78958 18.4556 3.68678H5.54428C5.92879 2.78958 6.83555 2.12352 7.94337 2.01515C8.09917 1.99991 8.27741 1.99995 8.50989 2.00001Z"
              fill="#ffffff"
            />
            <path
              d="M6.31052 4.72312C4.91989 4.72312 3.77963 5.56287 3.3991 6.67691C3.39117 6.70013 3.38356 6.72348 3.37629 6.74693C3.77444 6.62636 4.18881 6.54759 4.60827 6.49382C5.68865 6.35531 7.05399 6.35538 8.64002 6.35547H15.5321C17.1181 6.35538 18.4835 6.35531 19.5639 6.49382C19.9833 6.54759 20.3977 6.62636 20.7958 6.74693C20.7886 6.72348 20.781 6.70013 20.773 6.67691C20.3925 5.56287 19.2522 4.72312 17.8616 4.72312H6.31052Z"
              fill="#ffffff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.3276 7.54204H8.67239C5.29758 7.54204 3.61017 7.54204 2.66232 8.52887C1.71447 9.5157 1.93748 11.0403 2.38351 14.0896L2.80648 16.9811C3.15626 19.3724 3.33115 20.568 4.22834 21.284C5.12553 22 6.4488 22 9.09534 22H14.9046C17.5512 22 18.8745 22 19.7717 21.284C20.6689 20.568 20.8437 19.3724 21.1935 16.9811L21.6165 14.0896C22.0625 11.0404 22.2855 9.51569 21.3377 8.52887C20.3898 7.54204 18.7024 7.54204 15.3276 7.54204ZM14.5812 15.7942C15.1396 15.4481 15.1396 14.5519 14.5812 14.2058L11.2096 12.1156C10.6669 11.7792 10 12.2171 10 12.9099V17.0901C10 17.7829 10.6669 18.2208 11.2096 17.8844L14.5812 15.7942Z"
              fill="#ffffff"
            />
          </svg>

          <span className="">كورساتي</span>
        </MyLink>
        <hr />
        <button
          className=" flex items-center gap-3  p-2 text-[#7F8189] rounded-lg duration-200 transition-all  group text-[18px]"
          onClick={() => {
            handleLogout();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.125 12C16.125 11.5858 15.7892 11.25 15.375 11.25L4.40244 11.25L6.36309 9.56944C6.67759 9.29988 6.71401 8.8264 6.44444 8.51191C6.17488 8.19741 5.7014 8.16099 5.38691 8.43056L1.88691 11.4306C1.72067 11.573 1.625 11.7811 1.625 12C1.625 12.2189 1.72067 12.427 1.88691 12.5694L5.38691 15.5694C5.7014 15.839 6.17488 15.8026 6.44444 15.4881C6.71401 15.1736 6.67759 14.7001 6.36309 14.4306L4.40244 12.75L15.375 12.75C15.7892 12.75 16.125 12.4142 16.125 12Z"
              fill="#7F8189"
            />
            <path
              d="M9.375 8C9.375 8.70219 9.375 9.05329 9.54351 9.3055C9.61648 9.41471 9.71025 9.50848 9.81946 9.58145C10.0717 9.74996 10.4228 9.74996 11.125 9.74996L15.375 9.74996C16.6176 9.74996 17.625 10.7573 17.625 12C17.625 13.2426 16.6176 14.25 15.375 14.25L11.125 14.25C10.4228 14.25 10.0716 14.25 9.8194 14.4185C9.71023 14.4915 9.6165 14.5852 9.54355 14.6944C9.375 14.9466 9.375 15.2977 9.375 16C9.375 18.8284 9.375 20.2426 10.2537 21.1213C11.1324 22 12.5464 22 15.3748 22L16.3748 22C19.2032 22 20.6174 22 21.4961 21.1213C22.3748 20.2426 22.3748 18.8284 22.3748 16L22.3748 8C22.3748 5.17158 22.3748 3.75736 21.4961 2.87868C20.6174 2 19.2032 2 16.3748 2L15.3748 2C12.5464 2 11.1324 2 10.2537 2.87868C9.375 3.75736 9.375 5.17157 9.375 8Z"
              fill="#7F8189"
            />
          </svg>
          <span className="">تسجيل خروج</span>
        </button>
      </div>
      {showProfileMenu && (
        <div
          className=" fixed top-0 left-0 w-full h-screen bg-[#ff1414] z-30 opacity-0"
          onClick={() => setShowProfileMenu(false)}
        ></div>
      )}
    </>
  );
};

export default UserLoggedMenu;
